var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"8"}},[_c('b-card',[_c('b-card-header',{staticClass:"student-details-card__header position-relative",attrs:{"header-class":"p-0 mb-2"}},[_c('div',{staticClass:"student-details-card__title-container d-flex"},[_c('unicon',{attrs:{"name":"users-alt","width":"22","height":"22"}}),_c('h4',{staticClass:"student-details-card__title ml-1 mb-0"},[_vm._v(" تفاصيل حساب الطالب ")])],1),_c('div',{staticClass:"student-details-card__teacher-status-container d-flex align-items-center"},[_c('b-badge',{attrs:{"pill":"","variant":_vm.studentDto.isSub ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.studentDto.isSub ? " مفعل" : "غير مفعل")+" ")])],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"rules":[
                                    {
                                        type: 'required',
                                        message: 'اسم الطالب مطلوب',
                                    } ],"name":"name","placeholder":"أدخل اسم الطالب","label":"اسم الطالب  "},model:{value:(_vm.studentDto.name),callback:function ($$v) {_vm.$set(_vm.studentDto, "name", $$v)},expression:"studentDto.name"}})],1),_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"rules":[
                                    {
                                        type: 'mobile',
                                        message:
                                            'يرجى ادخال رقم الموبايل صحيح',
                                    } ],"name":"phoneNumber","placeholder":"أدخل رقم الموبايل ","label":"رقم الموبايل"},model:{value:(_vm.studentDto.phoneNumber),callback:function ($$v) {_vm.$set(_vm.studentDto, "phoneNumber", $$v)},expression:"studentDto.phoneNumber"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('ek-date-picker',{attrs:{"name":"تاريخ الميلاد ","placeholder":"ادخل تاريخ الميلاد ","label":"تاريخ الميلاد "},model:{value:(_vm.studentDto.birthdate),callback:function ($$v) {_vm.$set(_vm.studentDto, "birthdate", $$v)},expression:"studentDto.birthdate"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"name":"email","placeholder":"ادخل البريد الإلكتروني","label":" البريد الإلكتروني"},model:{value:(_vm.studentDto.email),callback:function ($$v) {_vm.$set(_vm.studentDto, "email", $$v)},expression:"studentDto.email"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"name":"password","placeholder":"******** ","label":" كلمة السر الجديدة","rules":[
                                    {
                                        type: 'min:8',
                                        message:
                                            'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                                    } ]},model:{value:(_vm.studentDto.newPassword),callback:function ($$v) {_vm.$set(_vm.studentDto, "newPassword", $$v)},expression:"studentDto.newPassword"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',[_c('label',{staticClass:"pb-1",staticStyle:{"font-size":"14px","padding-top":"8px"}},[_vm._v(" الجنس:")]),_c('b-form-radio-group',{attrs:{"options":_vm.options,"value-field":"item","text-field":"name"},model:{value:(_vm.studentDto.gender),callback:function ($$v) {_vm.$set(_vm.studentDto, "gender", $$v)},expression:"studentDto.gender"}})],1)])],1),_c('div',{staticClass:"d-block"},[_c('label',{staticStyle:{"font-size":"14px","margin-top":"7px","margin-right":"13px"}},[_vm._v("الجامعات والكليات المشترك بها :")]),(_vm.studentDto.universities.length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.studentDto.universities),function(item){return _c('b-col',{key:item.id,attrs:{"cols":"12","lg":"4"}},[_c('b-badge',{staticStyle:{"padding":"5px","margin-top":"8px"},attrs:{"pill":"","variant":"primary"}},[_vm._v(_vm._s(item.faculty + "-" + item.university + "-" + item.department + "-" + item.year)+" ")])],1)}),1):_c('div',[_c('b-badge',{staticStyle:{"padding":"5px","margin-top":"8px"},attrs:{"pill":"","variant":"primary"}},[_vm._v("لا يوجد ")])],1)])],1)],1),_c('b-col',{staticClass:"position-relative",attrs:{"cols":"12","md":"12","lg":"4"}},[_c('img',{staticClass:"h-100 w-100 rounded",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                        '/' +
                        _vm.studentDto.url,"alt":""}})])],1)],1),_c('div',{staticClass:"mt-2"},[_c('ValidationObserver',{ref:"codes"},[_c('b-form',[_c('ek-dialog',{ref:"addStudentDialog",attrs:{"title":"إضافة كود","size":"md","btnText":" إضافة كود "},on:{"ok":function($event){return _vm.ActiveStudentCode(_vm.activeCode)},"close":_vm.resetData},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ek-input-select',{attrs:{"placeholder":"اسم الحزمة","label":"  اسم الحزمة  ","name":"  اسم الحزمة  ","options":_vm.packageList,"clearable":true,"rules":[
                                {
                                    type: 'required',
                                    message: 'اسم الحزمة مطلوب',
                                } ]},model:{value:(_vm.packageId),callback:function ($$v) {_vm.packageId=$$v},expression:"packageId"}}),_c('ek-input-select',{attrs:{"name":"كود ","label":"اختر كود","placeholder":" اختر كود معينة","options":_vm.packageDto.codes.filter(
                                    function (el) { return el.isActive == false &&
                                        el.studentName == null; }
                                ),"textLabel":"code","valueLabel":"code","clearable":true,"rules":[
                                {
                                    type: 'required',
                                    message: 'الكود مطلوب',
                                } ]},model:{value:(_vm.activeCode),callback:function ($$v) {_vm.activeCode=$$v},expression:"activeCode"}})]},proxy:true}])})],1)],1)],1),_c('ek-table',{staticClass:"mt-2 mb-[-10px]",attrs:{"title":" الحزم المشتركة","columns":_vm.headerDetails,"items":_vm.studentDto.codes || []},on:{"details":_vm.goToDetails},scopedSlots:_vm._u([{key:"items.startDate",fn:function(ref){
                            var value = ref.value;
return [_vm._v(" "+_vm._s(value ? new Date(value).toLocaleDateString() : "_")+" ")]}},{key:"items.years",fn:function(ref){
                            var props = ref.props;
return [_c('b-button',{attrs:{"id":("popover-target-" + (props.row.originalIndex)),"variant":"transperant"}},[_vm._v(" مرر للرؤية ")]),_c('b-popover',{attrs:{"target":("popover-target-" + (props.row.originalIndex)),"triggers":"hover","placement":"right"}},[_c('span',[_vm._v(" "+_vm._s(props.row.years.length > 0 ? props.row.years.find(function (ele) { return ele; }) : "لايوجد")+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }